import { Container, Row, Col, Modal, Button } from "react-bootstrap"
import { ArrowRightCircle } from 'react-bootstrap-icons'
import { useState } from 'react'
import Image from 'react-bootstrap/Image'
import headerImg from '../../assets/logos/BTMlogo.png'
import modalImg from '../../assets/img/person-persenting-large-group-3.png'
import 'animate.css';

export const Banner = () => {

    const [showJoinNow, setShowJoinNow] = useState(false)
    const handleCloseJoinNow = () => setShowJoinNow(false)
    const handleShowJoinNow = () => setShowJoinNow(true)

    return (
        <section className="banner" id="home">
            <Container>
                <Row className="align-items-center">
                    <Col xs={12} md={6} xl={5} className="mb-60" >
                        <div className="animate__animated animate__zoomIn">
                            <img src={headerImg} alt="Header Img" />
                        </div>
                    </Col>
                    <Col xs={12} md={6} xl={7}>
                        <div className="animate__animated animate__fadeIn">
                            <h1 className="animate__animated animate__slideInUp"><span className="bold">EXPLORE THE POWER</span><br /><span>OF YOUR OWN VOICE</span></h1>
                            <p><br /><br /></p>
                            <p className="border-top-bottom subtitle mb-30 mt-30 animate__animated animate__slideInUp">
                                When: Every Tuesday, 18:30
                            </p>
                            <p>
                                Where:
                                <a
                                    href='https://maps.app.goo.gl/uxTCoa16ZdEMWTNr8'
                                    target='_blank'
                                    rel="noreferrer"
                                >
                                    &nbsp;KŘENOVÁ 57, OBČANSKÝ KLUB
                                </a>
                            </p>

                            <button onClick={handleShowJoinNow} className="border-top-bottom subtitle mb-30 mt-30 animate__animated animate__slideInUp">How to join <ArrowRightCircle size={25} /></button>
                            <Modal show={showJoinNow} onHide={handleCloseJoinNow} className='' centered data-bs-theme={'dark'}>
                                <Modal.Header closeButton >
                                    <Modal.Title>How to join?</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Row>
                                        <Col xs={12} md={6} >
                                            <Image src={modalImg} alt="A man presenting in a meeting" fluid />
                                        </Col>
                                        <Col>
                                            <ul>
                                                <li>Remember the meeting time</li>
                                                <li>Come to our meeting location</li>
                                                <li>Ring the bell to OBČANSKÝ KLUB</li>
                                                <li>Bring with you a smile</li>
                                                <li>No registration required</li>
                                                <li>No membership required</li>
                                                <li><h3>Free to join anytime</h3></li>
                                            </ul>
                                        </Col>
                                    </Row>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleCloseJoinNow}>
                                        Close
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section >
    )
}
