import { Form, Button, Modal, InputGroup } from 'react-bootstrap'

import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { useState } from 'react'

export default function UserAdd({ getUsers = null }) {

  const url = process.env.REACT_APP_URL
  const { user: authUser } = useSelector(x => x.auth)

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [saveLoading, setSaveLoading] = useState(false)

  const [showModal, setShowModal] = useState(false)
  const handleShowModal = () => setShowModal(true)
  const handleCloseModal = () => setShowModal(false)

  const handleSubmit = (e) => {
    e.preventDefault()
    setSaveLoading(true)
    fetch(`${url}/users`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authUser.token
      },
      body: JSON.stringify({
        firstName: firstName,
        lastName: lastName,
        email: email,
        password: 'dummypass',
        acl: 'Guest'
      })
    })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error('(' + res.status + ') We could not save your new user')
        }
        return res.json()
      })
      .then(resData => {
        toast.success('User ' + firstName + ' ' + lastName + ' has been created', { position: 'bottom-left' })
        setSaveLoading(false)
        if (getUsers) getUsers()
        handleCloseModal()
      })
      .catch(err => {
        toast.error('' + err, { position: 'bottom-left' })
        setSaveLoading(false)
      })
  }


  return (
    <>
      <Button variant={'success'} className='float-end' onClick={handleShowModal}>+ Add User</Button>
      <Modal centered show={showModal} onHide={handleCloseModal} className='text-center' data-bs-theme={'dark'}>
        <Modal.Title>
          Add New User
        </Modal.Title>
        <Form>
          <Modal.Body>
            <InputGroup className='mb-3'>
              <Form.Control
                className='formInput'
                placeholder='First Name'
                aria-label='First Name'
                name='firstName'
                onChange={e => setFirstName(e.target.value)}
              />
            </InputGroup>
            <InputGroup className='mb-3'>
              <Form.Control
                className='formInput'
                placeholder='Last Name'
                aria-label='Last Name'
                name='lastName'
                onChange={e => setLastName(e.target.value)}
              />
            </InputGroup>
            <InputGroup className='mb-3'>
              <Form.Control
                className='formInput'
                placeholder='Email'
                aria-label='Email'
                name='email'
                onChange={e => setEmail(e.target.value)}
              />
            </InputGroup>
          </Modal.Body>
          <Modal.Footer>
            {saveLoading
              ?
              'Adding user ... '
              :
              <>
                <Button variant="success" type='submit' onClick={handleSubmit}>
                  Add User
                </Button>
                <Button variant="danger" onClick={handleCloseModal}>
                  Cancel
                </Button>
              </>
            }
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}