// import { useState } from "react"
import { Card, Row, Col, Button, Modal } from "react-bootstrap"
import { useContext, useState } from "react"
import { ProfileContext } from "pages/Profile"
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { authActions } from '_store'

export default function DeleteAccount() {

  const { profile } = useContext(ProfileContext)
  const url = process.env.REACT_APP_URL

  const dispatch = useDispatch()
  const { user: authUser } = useSelector(x => x.auth)
  const navigate = useNavigate()

  const [saveLoading, setSaveLoading] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const handleCloseModal = () => setShowDeleteModal(false)
  const handleShowModal = () => setShowDeleteModal(true)

  const deleteUserFromDb = async () => {
    const response = await fetch(
      `${url}/users/${profile._id}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authUser.token}`
        }
      }
    )
    const result = await response.json()
    return result
  }

  const handleDelete = async (e) => {
    e.preventDefault()
    setSaveLoading(true)
    if (authUser.user._id === profile._id) {
      dispatch(authActions.logout())
    }
    else {
      console.log(`${authUser.user._id} - ${profile._id}`)
    }
    const result = await deleteUserFromDb()
    if (result) {
      navigate(`/users`)
    }
    setSaveLoading(false)
  }


  return (
    <Card data-bs-theme={'dark'} className="mt-3 mb-3">
      <Card.Header>
        <Row className="mt-3 mb-3"><Col>Delete account for {profile.firstName} {profile.lastName}</Col></Row>
      </Card.Header>
      <Card.Footer>
        <Row className="mt-3 mb-3">
          <Col className="text-center"><Button variant="danger" onClick={handleShowModal}>Delete Account</Button></Col>
          < Modal centered show={showDeleteModal} onHide={handleCloseModal} className='text-center' data-bs-theme={'dark'}>
            <Modal.Header closeButton>
              <Modal.Title>

              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h2>Delete user: </h2><h4>{profile.firstName} {profile.lastName}?</h4>
              Are you sure you want to delete this user?
            </Modal.Body>
            <Modal.Footer>
              {saveLoading
                ?
                'Deleting User... '
                :
                <>
                  <Button variant='danger' type='submit' onClick={handleDelete}>
                    Delete User
                  </Button>
                  <Button variant='dark' onClick={handleCloseModal}>
                    Cancel
                  </Button>
                </>
              }
            </Modal.Footer>
          </Modal >
        </Row>
      </Card.Footer>
    </Card>
  )
}