import { Container, Row, Col, Card } from 'react-bootstrap'
import { ArrowRightCircle } from 'react-bootstrap-icons'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'

import { fetchWrapper } from '_helpers/fetch-wrapper'

export const NextMeeting = () => {

  const [isLoading, setIsLoading] = useState(true, [])
  const [meeting, setMeeting] = useState(true, [])

  const url = process.env.REACT_APP_URL

  const getMeetings = async () => {
    setIsLoading(true)
    try {
      const response = await fetchWrapper.get(`${url}/meetings/next`)
      setMeeting(response)
    }
    catch (e) {
      console.log('Next meeting: ' + e)
    }
    // console.log(meeting)
    setIsLoading(false)
  }
  const noBgImage = process.env.REACT_APP_NO_BG_IMAGE

  const backgroundImage = (meeting.imageUrl === '' || meeting.imageUrl === 'undefined' || !meeting.imageUrl) ? noBgImage : meeting.imageUrl

  useEffect(() => {
    getMeetings()
    // eslint-disable-next-line
  }, [])

  return (
    <section className="nextMeeting" id="nextMeeting">
      <Container>
        {/* <Row className='align-items-center'>
          <Col xs={12} md={12} xl={12} style={{ "backgroundColor": "red", "marginTop": "-80px", "marginBottom": "80px", "padding": "12px", "text-align": "center" }}>
          <Col xs={12} md={12} xl={12} style={{ "backgroundColor": "red", "marginTop": "-80px", "marginBottom": "80px", "padding": "12px", "textAlign": "center" }}>
            <h3>The meeting on 7th May 2024 has been postponed due to holidays to 14th May 2024!</h3>
          </Col>
        </Row> */}
        <Row className="align-items-center">
          <Col xs={12} md={6} xl={6}>
            <Row>
              <Col>
                <h2>Join our Next Meeting</h2>
                <p>
                  Be our Guest!<br />
                  Overcome the fear of public speaking!<br />
                  Get a role!<br />
                  Grow your leadership skills!
                </p>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={6} xl={6}>
            {(meeting) &&
              <Card className="text-center">
                {
                  (isLoading)
                    ?
                    <Spinner animation="border" variant="danger" />
                    :
                    <Card.Img variant="top" src={backgroundImage} alt="cardImage1" />}
                <div className='nextMeeting-overlay'>
                </div>
                {(meeting) && <Link to={(meeting._id === undefined) ? "/meetings/" : "/meeting/" + meeting._id} className='stretched-link'>{(meeting._id === undefined ? 'Open the meetings page' : 'Open the meeting agenda')} <ArrowRightCircle size={25} /></Link>}
              </Card>
            }
            <Row>
              <Col>
                <Link to={'/meetings'} >See all meetings <ArrowRightCircle size={25} /></Link>
              </Col>
            </Row>
          </Col>
        </Row>

      </Container>
    </section>
  )
}