import { Card, Row, Col } from "react-bootstrap"
import { useSelector } from "react-redux"
import { Spinner } from 'react-bootstrap'
import { useEffect, useState } from "react"
import Avatar from '../Users/Header/Avatar'
import Profile from '../Users/Header/Profile'
// import Pathways from '../Users/Header/Pathways'
import UserAdd from "./crudModals/UserAdd"


export default function ListUsers() {

  const { user: authUser } = useSelector(x => x.auth)
  const url = process.env.REACT_APP_URL

  const [isLoading, setIsLoading] = useState(true, [])
  const [userList, setUserList] = useState([])

  const getUsers = async () => {
    setIsLoading(true)
    await fetch(`${url}/users/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authUser.token
      },
    })
      .then(res => {
        if (res.status !== 200 && res.status !== 201) {
          throw new Error('(' + res.status + ') We could not retrieve the users')
        }
        return res.json()
      })
      .then(resData => {
        const sortedUserList = resData.sort((a, b) => {
          if (a.acl === b.acl) {
            return b.lastName.localeCompare(a.lastName);
          }
          return a.acl.localeCompare(b.acl);
        }).reverse();
        setUserList(sortedUserList);
      })
    setIsLoading(false)
  }

  useEffect(() => {
    getUsers()
    //eslint-disable-next-line
  }, [])

  return (
    <section >
      {
        isLoading ?
          <Spinner animation='border' variant='danger' />
          :
          <>
            <Row>
              <Col>
                <h5 className="mb-5">Users List</h5>
              </Col>
              <Col className='text-end'>
                <UserAdd getUsers={getUsers} />
              </Col>
            </Row>
            <Row>
              <Col>
                {userList.map((user) => (
                  <Card className='mb-2' key={user._id}>
                    <Card.Body>
                      <Row>
                        <Col xs={12} lg={3}>
                          <Avatar profile={user} />
                        </Col>
                        <Col className='mt-4' xs={8} lg={9}>
                          <Row>
                            <Profile profile={user} />
                            {/* <Pathways profile={user} /> */}
                          </Row>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card >
                ))}
              </Col>
            </Row>
          </>
      }
    </section >
  )
}