import { Container, Row, Col } from 'react-bootstrap'
import { useSelector } from "react-redux"

import MeetingAddNewModal from 'components/Meetings/crudModals/MeetingAddNewModal'
import MeetingCard from './MeetingCard'

export default function MeetingComponent() {

  const { user: authUser } = useSelector(x => x.auth)

  return (
    <Container>
      <Row className='header'>
        {(authUser && authUser.user.acl === 'Officer') &&
          <Col key={'newMeeting'} >
            <MeetingAddNewModal />
          </Col>
        }
      </Row>
      <Row>
        <Col><MeetingCard /></Col>
      </Row>
    </Container>
  )
}