
import { Image } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { NavDropdown } from "react-bootstrap"
import { authActions } from '_store'
import signOutImg from '../../assets/svg/brand-logos/signout.svg'
import { useState, useEffect } from 'react'

export const Signout = () => {
  const dispatch = useDispatch()

  const { user: authUser } = useSelector(x => x.auth)
  const [avatar, setAvatar] = useState(signOutImg)

  useEffect(() => {
    if (authUser.user.avatar) setAvatar('data:image/png;base64, ' + authUser.user.avatar)
  }, [avatar, authUser.user.avatar])

  const signout = (e) => {
    e.preventDefault()
    dispatch(authActions.logout())
  }

  return (
    <NavDropdown id='basic-navbar-nav' title={<Image src={avatar} style={{ width: '40px', height: '40px' }} />} data-bs-theme={'dark'} >

      <NavDropdown.Item as={Link} to={`/profile/${authUser.user._id}`} style={{ color: 'white' }}>Profile</NavDropdown.Item>
      <NavDropdown.Divider />
      <NavDropdown.Item onClick={signout} style={{ color: 'white' }}>Signout</NavDropdown.Item>
    </NavDropdown>
  )
}